/* eslint-disable no-nested-ternary */
import {
  TOOGLE_NAV,
  FILTER_POST,
  STORE_POST,
  SORT_DESC,
  SORT_ASC,
} from 'Redux/actionTypes';

export const toggleNav = () => ({
  type: TOOGLE_NAV,
});

export const storePost = (data: any) => ({
  type: STORE_POST,
  payload: data,
});

export const filterPosts =
  (tags: string[]) => (dispatch: any, getState: any) => {
    const { SortAndFilterReducer } = getState();
    if (tags.length === 0 || tags.includes('All')) {
      const { searchResults } = SortAndFilterReducer;
      return dispatch({ type: FILTER_POST, payload: searchResults });
    }
    const filteredPosts = SortAndFilterReducer.searchResults.filter(
      (post: any) => {
        const postTags = post.node.article_tags?.length
          ? post.node.article_tags.map((tag: any) => tag.title.toLowerCase())
          : [];
        if (postTags.length === 0) {
          return false;
        }
        return tags.some(tag => postTags.includes(tag.toLowerCase()));
      }
    );
    return dispatch({ type: FILTER_POST, payload: filteredPosts });
  };

export const sortPostsAsc = () => (dispatch: any, getState: any) => {
  const { SortAndFilterReducer } = getState();
  const sortedPosts = SortAndFilterReducer.posts.sort((a: any, b: any) =>
    a.node.date < b.node.date ? -1 : a.node.date > b.node.date ? 1 : 0
  );
  dispatch({ type: SORT_ASC, payload: sortedPosts });
};

export const sortPostsDesc = () => (dispatch: any, getState: any) => {
  const { SortAndFilterReducer } = getState();
  const sortedPosts = SortAndFilterReducer.posts.sort((a: any, b: any) =>
    a.node.date < b.node.date ? 1 : a.node.date > b.node.date ? -1 : 0
  );
  dispatch({ type: SORT_DESC, payload: sortedPosts });
};
