import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { CARD_SLIDE_UP_ANIMATION } from 'types';

gsap.registerPlugin(ScrollTrigger);

function CardSlideup({
  children,
  className,
  direction = 'left',
}: CARD_SLIDE_UP_ANIMATION) {
  const cardsRef = useRef() as React.MutableRefObject<HTMLSpanElement>;

  const CardSpanStyle = {
    display: 'block',
  } as React.CSSProperties;

  useEffect(() => {
    const CardAnimationPosition = window.innerWidth < 768 ? 200 : 100;
    let xvalue;
    if (direction === 'right') {
      xvalue = 300;
    } else {
      xvalue = -300;
    }
    gsap.fromTo(
      cardsRef.current,
      { x: xvalue, opacity: 0 },
      {
        duration: 1.1,
        x: 0,
        y: 0,
        opacity: 1,
        ease: 'power2.inOut',
        scrollTrigger: {
          trigger: cardsRef.current,
          start: `top center+=${CardAnimationPosition}`,
          toggleActions: 'play none none reverse',
        },
      }
    );
  }, [direction]);

  return (
    <span
      ref={cardsRef}
      aria-hidden
      style={CardSpanStyle}
      className={className}
    >
      {children}
    </span>
  );
}

export { CardSlideup };
