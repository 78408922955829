/* eslint-disable camelcase */
/* eslint-disable react/function-component-definition */
import React from 'react';
import { Link } from 'gatsby';
import Moment from 'react-moment';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { BLOG_CARD_PROP } from 'types';

const BlogCard = ({ blogCard, parentUrl }: BLOG_CARD_PROP) => {
  const {
    title = '',
    slug = '',
    article_tags = [],
    image,
    date,
    person,
    timeRead,
    description = {
      data: {
        description: '',
      },
    },
  } = blogCard;

  const Image: any = getImage(image.localFile);
  const PersonImage: any = getImage(person.image.localFile);
  return (
    <div className="blogcard-container">
      <Link to={`/${parentUrl}/${slug}`}>
        <div className="blogcard-image">
          {image && <GatsbyImage image={Image} alt={title} />}
        </div>
        <div className="blogcard-tag-wrapper">
          {article_tags &&
            article_tags.map(item => (
              <div className="blogcard-tag card">{item.title}</div>
            ))}
        </div>
        {title && <h3 className="blogcard-title">{title}</h3>}
        {description.data.description && (
          <p className="blogcard-desc">{description.data.description} </p>
        )}
        <div className="blogcard-people-wrapper">
          <div className="blogcard-people-detail">
            <div className="blogcard-people-img">
              {' '}
              {person.image && <GatsbyImage image={PersonImage} alt={title} />}
            </div>
            <div className="blogcard-people-desc">
              {person.name && <p className="author-name"> {person.name}</p>}
              {person.position && (
                <p className="author-position"> {person.position}</p>
              )}
            </div>
          </div>
          {date && (
            <div className="blogcard-date">
              <Moment format="Do MMM YYYY">{date}</Moment>
              <span className="timeRead">{timeRead}</span>
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};
export { BlogCard };
