import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MarkDown } from 'components';
import { SHARED_IMAGE_AND_DESCRIPTION_PROP } from 'types';

export const ImageAndDescription = ({
  data,
}: SHARED_IMAGE_AND_DESCRIPTION_PROP) => {
  const Image =
    data?.image && data.image?.localFile
      ? getImage(data.image?.localFile?.childImageSharp?.gatsbyImageData)
      : null;

  return (
    <div className="page-content-block-item">
      <MarkDown className="page-content-description">
        {data?.content?.data?.content}
      </MarkDown>
      {Image && (
        <GatsbyImage image={Image} alt={data.image?.alternativeText || ''} />
      )}
    </div>
  );
};
