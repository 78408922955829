/* eslint-disable react/function-component-definition */
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Moment from 'react-moment';
import { AllDynamicComponents, SocialIcons, MarkDown } from 'components';
import UserIcon from 'assets/svg/user.svg';

const ArticleComponent = ({ data }: any) => {
  const Image: any = getImage(data.image.localFile);
  const { dynamicComponents, person } = data;
  const authorImage: any = getImage(person?.image?.localFile);
  return (
    <div className="container article-page-wrapper article-page">
      <div className="article-page-block">
        <div className="article-page-category">
          {data.article_tags &&
            data.article_tags.map((tag: any) => (
              <div className="article-page-category-item">{tag.title}</div>
            ))}
        </div>
        {data.title && <h1 className="article-page-title">{data.title}</h1>}
        <div className="article-page-author-block">
          <div className="article-page-author">
            <div className="article-page-author-img">
              {authorImage ? (
                <GatsbyImage image={authorImage} alt={person.name} />
              ) : (
                <UserIcon />
              )}
            </div>
            <div className="article-page-author-content">
              <p className="article-page-author-content-name">{person.name}</p>
              <div className="article-page-author-content-details">
                <span>
                  <Moment format="DD MMM YYYY">{data.date}</Moment>
                </span>{' '}
                <span className="dot" />
                <span>{data.timeRead}</span>
              </div>
            </div>
          </div>
          <SocialIcons data={person.socialMediaLink} />
        </div>
      </div>
      <div className="article-page-details-sidebar">
        <div className="article-page-image">
          {Image && <GatsbyImage image={Image} alt={data.title} />}
        </div>
      </div>
      <div className="article-page-details article-page-block">
        <div className="article-page-details-main">
          {data.description && (
            <div className="article-page-details-description">
              <MarkDown>{data.description?.data?.description}</MarkDown>
            </div>
          )}
        </div>
      </div>

      {dynamicComponents && dynamicComponents?.length > 0 && (
        <div className="article-page-block">
          <AllDynamicComponents dynamicComponents={dynamicComponents} />
        </div>
      )}
      <div className="article-page-author-section">
        <div className="article-page-author-img">
          {authorImage ? (
            <GatsbyImage image={authorImage} alt={person.name} />
          ) : (
            <UserIcon />
          )}
        </div>
        <p className="article-page-author-section-name">{person.name}</p>
        <p className="article-page-author-section-position">
          {person.position}
        </p>
      </div>
    </div>
  );
};

export { ArticleComponent };
