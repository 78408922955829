/* eslint-disable react/function-component-definition */
import React from 'react';
import MailIcon from 'assets/svg/email.svg';
import FacebookIcon from 'assets/svg/facebook.svg';
import LinkedInIcon from 'assets/svg/linkedin.svg';
import { SHARED_SOCIAL_MEDIA_LINK_PROP } from 'types';
import { CTALink } from 'components';

export const SocialIcons = ({ data }: SHARED_SOCIAL_MEDIA_LINK_PROP) => {
  return (
    <div className="article-page-author-socials">
      {data?.linkedInLink && (
        <CTALink ctaLink={data.linkedInLink}>
          <LinkedInIcon />
        </CTALink>
      )}
      {data?.mailLink && (
        <CTALink ctaLink={data.mailLink}>
          <MailIcon />
        </CTALink>
      )}
      {data?.facebookLink && (
        <CTALink ctaLink={data.facebookLink}>
          <FacebookIcon />
        </CTALink>
      )}
    </div>
  );
};
