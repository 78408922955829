/* eslint-disable react/function-component-definition */
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MarkDown } from 'components';
import { SHARED_TWO_IMAGE_CONTENT_PROP } from 'types';

export const TwoImageContent = ({ data }: SHARED_TWO_IMAGE_CONTENT_PROP) => {
  return (
    <div className="two-image-content-section">
      <h2 className="article-content-title">{data.title}</h2>
      <MarkDown>{data.description.data.description}</MarkDown>
      <div className="image-grid-wrapper">
        {data.images &&
          data.images.map((item, index) => {
            const ImageItem =
              item.image && item.image.localFile
                ? getImage(item.image.localFile.childImageSharp.gatsbyImageData)
                : null;
            if (ImageItem) {
              return (
                <GatsbyImage
                  key={index}
                  image={ImageItem}
                  alt={item.image.alternativeText || data.title}
                  objectFit="contain"
                />
              );
            }
            return null;
          })}
      </div>
    </div>
  );
};
