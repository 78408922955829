/* eslint-disable default-param-last */
import {
  FILTER_POST,
  STORE_POST,
  SORT_DESC,
  SORT_ASC,
} from 'Redux/actionTypes';

type InitialStateType = {
  posts: [];
  searchResults: [];
};

const InitialState: InitialStateType = {
  posts: [],
  searchResults: [],
};

const SortAndFilterReducer: any = (state = InitialState, action: any) => {
  switch (action.type) {
    case STORE_POST:
      return { ...state, posts: action.payload, searchResults: action.payload };
    case FILTER_POST:
      return { ...state, posts: action.payload };
    case SORT_ASC:
      return { ...state, posts: action.payload };
    case SORT_DESC:
      return { ...state, posts: action.payload };

    default:
      return state;
  }
};
export { SortAndFilterReducer };
