import React from 'react';
import { MarkDown } from 'components';
import { SHARED_TITLE_SUMMARY_PROP } from 'types';

export const TitleSummary = ({ data }: SHARED_TITLE_SUMMARY_PROP) => {
  return (
    <div className="content-section">
      <h3 className="content-section-title">{data.title}</h3>
      <MarkDown>{data.summary.data.summary}</MarkDown>
    </div>
  );
};
