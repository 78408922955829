import React from 'react';
import { CtaButton, MarkDown, SectionHeading } from 'components';
import { SlideUpPageContent } from 'components/Animation';
import { SHARED_CONTENT_SECTION_PROP } from 'types';

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

export function ContentSection({ data }: SHARED_CONTENT_SECTION_PROP) {
  const { heading, title, headingLevel, description, ctaTitle, ctaLink } = data;
  let headingLevelNum = 2;
  switch (headingLevel) {
    case 'h1':
      headingLevelNum = 1;
      break;
    case 'h2':
      headingLevelNum = 2;
      break;
    case 'h3':
      headingLevelNum = 3;
      break;
    default:
      headingLevelNum = 2;
  }
  return (
    <div className="container">
      {(heading || title) && (
        <SectionHeading
          heading={heading}
          headingLevel={headingLevelNum as 1 | 2 | 3}
          tagline={title}
          align="left"
        />
      )}
      <div className="content-section-container">
        <SlideUpPageContent>
          {description?.data?.description && (
            <div className="content-section-description">
              <MarkDown>{description.data.description}</MarkDown>
            </div>
          )}
          {ctaTitle && ctaLink && (
            <div className="content-section-cta-container">
              <CtaButton ctaTitle={ctaTitle} ctaLink={ctaLink} />
            </div>
          )}
        </SlideUpPageContent>
      </div>
    </div>
  );
}
