import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MarkDown } from 'components';
import { SHARED_IMAGE_AND_CONTENT_PROP } from 'types';

export const ImageAndContent = ({ data }: SHARED_IMAGE_AND_CONTENT_PROP) => {
  const {
    title = '',
    description = {
      data: {
        description: '',
      },
    },
    image = null,
  } = data;

  const Image =
    image && image.localFile
      ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
      : null;

  return (
    <div className="problem-solution-wrapper" key={title}>
      <div className="problem-solution-img">
        {Image && (
          <GatsbyImage image={Image} alt={image?.alternativeText || title} />
        )}
      </div>
      <div className="problem-solution-detail">
        {title && (
          <h2 className="problem-solution-detail-title">
            <MarkDown context="heading">{title}</MarkDown>
          </h2>
        )}
        {description?.data?.description && (
          <MarkDown className="problem-solution-detail-desc">
            {description.data.description}
          </MarkDown>
        )}
      </div>
    </div>
  );
};
