/* eslint-disable react/function-component-definition */
import React from 'react';
import {
  Launch,
  OptimalStack,
  ServiceIconSection,
  MeetOurTeam,
  CardIconSection,
  AboutUsContent,
  FactsAndFigures,
  ProfileDetail,
  TitleSummary,
  TwoImageContent,
  SocialIcons,
  WorkChallengeSolutionResult,
  AboutProject,
  Quote,
  ImageAndContent,
  ImageAndDescription,
} from 'components';
import { SlideUpPageContent, CardAnimation } from 'components/Animation';
import { ALL_DYNAMIC_PROP } from 'types';

function AllDynamicComponents({ dynamicComponents }: ALL_DYNAMIC_PROP) {
  return (
    <div>
      {dynamicComponents?.map((section: any) => {
        return (
          <React.Fragment key={section.id}>
            {section.internal?.type === 'STRAPI__COMPONENT_SHARED_LAUNCH' && (
              <Launch launch={section} />
            )}
            {section.internal?.type ===
              'STRAPI__COMPONENT_PAGE_OPTIMAL_STACK' && (
              <OptimalStack optimalStack={section} />
            )}
            {section.internal?.type ===
              'STRAPI__COMPONENT_PAGE_SERVICE_ICONS_SECTION' && (
              <ServiceIconSection serviceIconsSection={section} />
            )}

            {section.internal?.type ===
              'STRAPI__COMPONENT_SHARED_TAGLINE_SUMMARY' && (
              <SlideUpPageContent>
                <MeetOurTeam meetOurTeam={section} />{' '}
              </SlideUpPageContent>
            )}

            {section.internal?.type === 'STRAPI__COMPONENT_PAGE_CORE_VALUE' && (
              <CardIconSection
                cardDetails={section.coreValue}
                cardTitle={section.title}
                cardDescription={section.sectionTagline}
              />
            )}

            {section.internal?.type === 'STRAPI__COMPONENT_PAGE_ABOUT_US' && (
              <SlideUpPageContent>
                <AboutUsContent aboutUs={section} />{' '}
              </SlideUpPageContent>
            )}

            {section.internal?.type ===
              'STRAPI__COMPONENT_PAGE_FACTS_AND_FIGURES' && (
              <SlideUpPageContent>
                <FactsAndFigures factsAndFigures={section} />{' '}
              </SlideUpPageContent>
            )}

            {section &&
              section.internal &&
              section.internal.type ===
                'STRAPI__COMPONENT_PAGE_OUR_SERVICES' && (
                <div className="card-icon-section-wrapper homepage-card">
                  <CardAnimation>
                    <CardIconSection
                      cardDetails={section.ourServicesList}
                      showAnimation={false}
                      cardTitle={section.title}
                      cardDescription={section?.description?.data?.description}
                    />
                  </CardAnimation>
                </div>
              )}
            {section.internal?.type ===
              'STRAPI__COMPONENT_SHARED_TEAM_MEMBERS' && (
              <ProfileDetail people={section.teamMembers} />
            )}
            {section?.internal?.type ===
              'STRAPI__COMPONENT_SHARED_TITLE_SUMMARY' && (
              <TitleSummary data={section} />
            )}
            {section?.internal?.type === 'STRAPI__COMPONENT_SHARED_QUOTE' && (
              <Quote data={section} />
            )}
            {section?.internal?.type ===
              'STRAPI__COMPONENT_SHARED_TWO_IMAGE_CONTENT' && (
              <TwoImageContent data={section} />
            )}
            {section?.internal?.type ===
              'STRAPI__COMPONENT_SHARED_SOCIAL_MEDIA_LINK' && (
              <div className="article-page-socials-section">
                <p>Share this post</p>
                <SocialIcons data={section} />
              </div>
            )}
            {section?.internal?.type ===
              'STRAPI__COMPONENT_SHARED_TITLE_SUMMARY_VIDEO_IMAGE' && (
              <WorkChallengeSolutionResult data={section} />
            )}
            {section?.internal?.type ===
              'STRAPI__COMPONENT_SHARED_IMAGE_AND_CONTENT' && (
              <ImageAndContent data={section} />
            )}
            {section?.internal?.type ===
              'STRAPI__COMPONENT_SHARED_TITLE_SUMMARY_IMAGE' && (
              <div className="our-work-details">
                <AboutProject data={section} />
              </div>
            )}
            {section?.internal?.type ===
              'STRAPI__COMPONENT_SHARED_IMAGE_AND_DESCRIPTION' && (
              <ImageAndDescription data={section} />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
export { AllDynamicComponents };
