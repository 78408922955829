import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { MarkDown } from 'components';
import Mouse from 'assets/svg/mouse.svg';
import { TitleAnimation } from 'components/Animation';
import { BANNER_CONTENT } from 'types';

function BannerContent({
  pageHeading,
  banner,
  durationTime = 1,
}: BANNER_CONTENT) {
  const [scrollMouse, setScrollMouse] = useState(false);
  const scrollToBottom = () => {
    window.scrollTo(0, 600);
  };
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerWidth <= 400) {
        setScrollMouse(window.scrollY > 200);
      }
      if (window.innerWidth <= 600) {
        setScrollMouse(window.scrollY > 350);
      } else {
        setScrollMouse(window.scrollY > 60);
      }
    });
  }, []);
  return (
    <div className="banner-section-content">
      {pageHeading && <h1>{pageHeading}</h1>}
      {banner?.bannerTitle?.data?.bannerTitle && (
        <h2>
          <MarkDown context="heading">
            {banner.bannerTitle.data.bannerTitle}
          </MarkDown>
        </h2>
      )}

      {banner?.description?.data?.description && (
        <div className="banner-section-para">
          <MarkDown>{banner.description.data.description}</MarkDown>
        </div>
      )}
      {banner?.consultationText && banner?.consultationLink && (
        <div className="content-section-cta-container">
          <Link to={banner.consultationLink} className="btn-wrapper">
            <span className="btn-wrapper-inner">{banner.consultationText}</span>
          </Link>
        </div>
      )}
      <button
        type="button"
        onClick={scrollToBottom}
        className={classNames('banner-section-mouse-icon', {
          scrolled: scrollMouse,
        })}
      >
        <Mouse />
        {banner?.ctaTitle ? (
          <span>{banner.ctaTitle}</span>
        ) : (
          <span>Scroll down</span>
        )}
      </button>
    </div>
  );
}

export { BannerContent };
