/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { useDispatch } from 'react-redux';
import { filterPosts, sortPostsDesc, sortPostsAsc } from 'Redux/actions';
import CloseIcon from 'assets/svg/Close.svg';
import DownArrow from 'assets/svg/down-arrow.svg';

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <DownArrow />
    </components.DropdownIndicator>
  );
};

export const SortAndFilter = ({ articleTags }: any) => {
  const dispatch = useDispatch();

  const [filter, setFilter] = useState<string[]>([]);
  const [sort, setSort] = useState('') as any;

  const options: any = [
    { value: 'desc', label: 'Newest to oldest' },
    { value: 'asc', label: 'Oldest to newest' },
  ];

  const categoryOptions = articleTags.map((item: any) => ({
    value: item.node.title,
    label: item.node.title,
  }));
  const sortStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: '#09151F',
      outline: 0,
      border: 0,
      boxShadow: 'none',
      color: '#eeeeee',
      cursor: 'pointer',
      minHeight: '50px',
      borderRadius: '30px',
    }),
    menu: (styles: any) => ({
      ...styles,
      borderRadius: '4px',
      textAlign: 'left',
      backgroundColor: '#09151F',
      color: '#eeeeee',
    }),
    option: (styles: any, { isDisabled, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: isSelected ? '#eeeeee' : 'none',
        color: isSelected ? '#09151F' : '#eeeeee',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        paddingLeft: '25px',
      };
    },
  };

  const handleChange = (selectedOptions: any) => {
    const selectedValueArray = selectedOptions
      ? selectedOptions.map((option: any) => option.value)
      : [];
    setFilter(selectedValueArray);
  };

  const handleRemove = (itemToRemove: any) => {
    setFilter(prevValues => prevValues.filter(item => item !== itemToRemove));
  };

  const handleClick = (item: string) => {
    if (filter.includes(item)) {
      setFilter(filter.filter(i => i !== item));
    } else {
      setFilter([...filter, item]);
    }
  };
  const handleClearAll = () => {
    setFilter([]);
  };
  useEffect(() => {
    dispatch(filterPosts(filter) as any);
    if (sort === 'asc') {
      dispatch(sortPostsAsc() as any);
    } else if (sort === 'desc') {
      dispatch(sortPostsDesc() as any);
    }
  }, [filter, sort]);
  return (
    <div className="blog-categories">
      <div className="blog-categories-wrapper mobile">
        <div className="">
          <Select
            options={categoryOptions}
            value={categoryOptions.filter((option: any) =>
              filter.includes(option.value)
            )}
            onChange={handleChange}
            placeholder="Categories"
            styles={sortStyles}
            controlShouldRenderValue={false}
            className="sort-select"
            classNamePrefix="select"
            isMulti
            components={{
              ClearIndicator: () => null,
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
          />
          <ul className="blog-categories-list">
            {filter.map((item: any) => {
              return (
                <li>
                  <button
                    type="button"
                    className="blogcard-tag active"
                    onClick={() => handleRemove(item)}
                  >
                    {item}
                    <CloseIcon />
                  </button>
                </li>
              );
            })}
          </ul>
          {filter.length > 0 && (
            <button
              type="button"
              className="blogcard-clearall"
              onClick={handleClearAll}
            >
              Clear All
            </button>
          )}
        </div>
      </div>
      <div className="blog-categories-wrapper">
        <h2 className="blog-categories-title">Categories</h2>
        <ul className="blog-categories-list">
          {articleTags.map((item: any) => {
            const { title } = item.node;
            return (
              <li>
                <button
                  type="button"
                  className={`blogcard-tag ${
                    filter.includes(title) ? 'active' : ''
                  }`}
                  onClick={() => handleClick(title)}
                >
                  {title}
                  {filter.includes(title) && <CloseIcon />}
                </button>
              </li>
            );
          })}
          {filter.length > 0 && (
            <li>
              <button
                type="button"
                className="blogcard-clearall"
                onClick={handleClearAll}
              >
                Clear All
              </button>
            </li>
          )}
        </ul>
      </div>

      <div className="sort">
        <Select
          className="sort-select"
          placeholder="Sort By"
          value={sort.value}
          onChange={e => setSort(e.value)}
          options={options}
          styles={sortStyles}
          classNamePrefix="select"
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator,
          }}
        />
      </div>
    </div>
  );
};
