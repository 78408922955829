import React from 'react';

export const Quote = ({ data }: any) => {
  const { title, quote, citeUrl, author } = data;
  return (
    <div className="quote-section">
      {title && <h3 className="quote-section-title">{quote.title}</h3>}
      <blockquote className="quote-section-quote" cite={citeUrl || ''}>
        {quote?.data?.quote}
      </blockquote>
      {author && <p className="quote-section-author">{author}</p>}
    </div>
  );
};
